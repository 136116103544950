@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Lilita+One');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&display=swap');

:root {
  @property --myColor1 {
      syntax: '<color>';
      initial-value: var(--joy-palette-neutral-100);
      inherits: false;
  }
  @property --myColor2 {
      syntax: '<color>';
      initial-value: var(--joy-palette-neutral-500);
      inherits: false;
  }
  
  @property --myColor3 {
      syntax: '<color>';
      initial-value: var(--joy-palette-neutral-50);
      inherits: false;
  }
  --fontMono: 'Fira Mono';

  --fontTitle: 'Roboto';

  --primary: #722974;
  --primary-gradient: linear-gradient(130deg, rgba(114,41,116,1) 15%, rgba(255,29,0,1) 50%, rgba(248,111,3,1) 85%);
  --primaryVariant: #8b408f;
  --accent: #F86F03;
  --secondary: #FFA41B;
  --secondaryVariant: #fcd293;
  --background: #FFF6F4;
  --text: #111111;
  --onPrimary: #ffffff;
  --outline: #ccc;

  --shadow: 0px 10px 15px -3px rgba(0,0,0,0.2);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Roboto';
}

html,
body {
  min-width: 100vw;
  overflow-x: hidden;
}

h1 {
  font-size: 40pt;
}

h2 {
  font-size: 20pt;
}

iframe{
  display: none
}

a {
  font-weight: bold;
  color: var(--accent);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
