.sliderWrapper {
	.slider {
        width: 100%;
        overflow-x: scroll;
        display: flex;
        gap: 1em;
        padding: 2em 0.5em;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;


        > *:first-child{
            padding-left: 0;
            margin-left: 0;
        }


		-ms-overflow-style: none; /* Internet Explorer 10+ */
		scrollbar-width: none; /* Firefox */

		&::-webkit-scrollbar {
			display: none; /* Safari and Chrome */
		}
	}
    .sliderBtn {
        position: absolute;
        z-index: 2;
        top: 50%;
        height: 48px;
        margin-top: -24px;
        border-radius: 500px;
        width: 48px;
    }
}
