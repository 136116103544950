.gridBox {
    background-color: var(--joy-palette-neutral-50);
    // background-color: var(--background);
    border-radius: 1rem;
    padding: 1em;
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: 100%;

}