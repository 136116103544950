.addressWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	border: 1px solid var(--primary);
	border-radius: 1rem;

	padding: 0.5em;

	.addressInfo {
		display: flex;
		flex-direction: column;
		margin-left: 0.5em;
	}
}
