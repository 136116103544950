.creator {
    height: 100vh;
    overflow: hidden;

    .topMenu{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em;
        background-color: var(--primary);

        .mugName {
            color: white;
        }

        .profileMenu {
            background-color: transparent;
            color: white;

            &:hover {
                opacity: 0.9;
            }

            .profileWrapper {
                display: flex;
                align-items: center;
    
                cursor: pointer;
    
                .text {
                    margin-left: 5px;
                }
            }

        }
    }

    .motor {
        @media (max-width: 768px) {
            height: calc(100vh - 90px);
            height: calc(100dvh - 90px);
            width: 100%;
        }
    }

    .contextMenu {
        display: flex;
        height: 90px;
        width: 100%;
    }

}