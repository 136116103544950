.cardButton {
    background-color: var(--joy-palette-neutral-100) !important;
    border-radius: 1rem !important;
    transition: 0.1s ease-in-out;
    cursor: pointer;
}

.cardButton .MuiSvgIcon-root{
    color: #000a !important;
}

.cardButton:hover{
    background-color: var(--joy-palette-neutral-200) !important;
}

.cardButton img {
    border-radius: 0.8rem;
}

.cardButton.active {
    background: var(--primary) !important;
    box-shadow: var(--shadow) !important;
}
.cardButton.active .MuiSvgIcon-root{
    color: var(--onPrimary) !important;
}

.cardButton.active .MuiTypography-root {
    color: var(--onPrimary);
}