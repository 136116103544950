#profileBoard {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2);
    margin-bottom: 3em;

    height: 30vh;

    overflow: hidden;

    border-radius: 1rem;

    background-color: black;

    .blobBox {
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;

        .blob {
            position: absolute;
            height: 300px;
            width: 300px;

            @media (max-width: 768px) {
                height: 150px;
                width: 150px;
            }
        }

        :nth-child(1) {
            bottom: -25%;
            left: 10%;
            
            @media (max-width: 768px) {
                bottom: -20%;
                left: -15%;
            }
        }

        :nth-child(2) {
            bottom: -25%;
            right: 0%;
            
            @media (max-width: 768px) {
                top: -15%;
                right: -5%;
                bottom: auto;
            }
        }
        
        :nth-child(3) {
            top: -20%;
            left: -5%;
            
            @media (max-width: 768px) {
                bottom: -5%;
                right: -5%;
                top: auto;
                left: auto;
            }
        }
        
        :nth-child(4) {
            top: -30%;
            right: 20%;
            
            @media (max-width: 768px) {
                top: 0%;
                left: -15%;
                right: auto;
            }
        }
        
    }


    .title {
        font-size: 30pt;
        font-family: "Bad Script";
        z-index: 1;

        color: white;
    }
}