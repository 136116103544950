.cards {
	transition: 0.3s;

    // background-color: var(--background);

	&:hover {
		scale: 1.01;
		box-shadow: -10px 10px 30px #bebebe, 20px -20px 60px #ffffff;
	}

    &.active {
        border-color: red;
    }
	.cardContent {
		.cardTitle {
		}

		.cardBody {
		}
	}
}
