.createNewMugBtn {
    color: black;
    background-color: var(--secondaryVariant);
    border: solid 1px var(--secondary);

    &:hover {
        background-color: var(--secondary);
        border: solid 1px var(--accent);
    }
}

footer {
    p {
        color: black;
    }

    .menuOption {
        padding: 0em 0.5em;
        border-radius: 500px;
        transition: 0.3s;

        &.active {
            scale: 1.05;
            color: var(--onPrimary);
            background: var(--primary);
            font-size: 24pt;

            :nth-child(1) {
                color: white;
            }
        }
    }
}