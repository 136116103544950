
.snapWrapper {
	overflow-y: scroll;
	overflow-x: hidden;
	scroll-behavior: smooth;

	.landingHeader {
		color: var(--onPrimary);
		height: 100vh;
		height: 101dvh;
		width: 100vw;

		#backgroundVideo {
			position: absolute;
			background-color: #333;
			width: 100vw;
			height: 101dvh;
			z-index: -1;
			filter: brightness(0.1);
			object-fit: cover;
		}

		.headerTopBarMobile {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: black;
			padding: 0.5em 16px;
			width: 100%;

			position: fixed;
			top: 0;
			z-index: 100;

			.mobileProfileWrapper {
				display: flex;
				align-items: center;
				cursor: pointer;

				.text {
					margin-right: 5px;
				}
			}

			a {
				text-decoration: none;
				margin: 0.5em;
				color: var(--onPrimary);
				font-size: 16pt;
				align-self: center;
				cursor: pointer;
			}

			a:visited,
			a:hover,
			a:active {
				color: inherit;
			}
		}

		.headerTopBar {
			width: 100vw;
			overflow-y: hidden;
			background-color: black;
			position: fixed;
			top: 0;
			z-index: 100;
			width: 100%;
			display: flex;
			justify-content: center;

			.topWrapper {
				padding: 0.5em 2em 0.5em 2em;
				max-width: 1300px;
				margin-left: 1em;
				width: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr;
				justify-content: space-between;

				> div,
				> a {
					padding: 0;
					margin: 0;
				}

				a.logoTop {
					height: 64px;
				}

				//scss for user menu when logged in*****
				.profileMenu {
					background-color: transparent;
					color: white;
					padding: 0;

					&:hover {
						opacity: 0.9;
					}

					.profileWrapper {
						display: flex;
						align-items: center;

						cursor: pointer;

						.text {
							margin-left: 5px;
						}
					}
				}

				.menuLink {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 0.5em;
					border-radius: 0.3rem;
					padding: 1em;
					transition: 0.2s;
					text-decoration: none;
					font-weight: normal;

					&:hover {
						scale: 1.05;
					}
				}

				a:visited,
				a:hover,
				a:active {
					color: inherit;
				}
			}
		}

		.headerContent {
			display: grid;
			max-width: 1300px;
			padding: 2em;
			grid-template-columns: 1fr;
			grid-template-rows: 4fr 1fr 3fr 1fr 1fr 1fr;
			grid-template-areas:
				"pics"
				"."
				"head"
				"."
				"buttons"
				"saiba";
			width: 100%;
			height: 100vh;
			height: 100dvh;

			a {
				text-decoration: none;
				margin: 0.5em;
				color: var(--onPrimary);
				font-size: 16pt;
				align-self: center;
				text-align: center;
			}

			a:visited,
			a:hover,
			a:active {
				color: inherit;
			}

			.headText {
				grid-area: head;
				display: flex;
				font-size: 8vw;
				backdrop-filter: blur(3px);
				background-color: #0005;
				flex-direction: column;
				align-items: center;
				align-self: flex-end;

				h2 {
					font-size: 22pt;
				}
			}

			.productImage {
				z-index: -1;
				grid-area: pics;
				list-style: none;
				scale: 1;
				display: flex;
				display: row;
				justify-content: center;
				width: 100%;

				li {
					width: 100%;

					img {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						opacity: 0;
						object-fit: contain;
						transition: 0.5s ease-in-out;

						&.show {
							opacity: 1;
						}
					}
				}
			}

			.saiba {
				grid-area: saiba;
				justify-self: center;
				font-size: 14pt;
			}
		}
	}

	main {
		min-height: 100vh;
		min-height: 100dvh;
		width: 100vw;
		background-color: var(--background);

		.homeWrapper {
			display: flex;
			justify-content: center;

			> * {
				max-width: 1300px;
			}
		}

		.playVideo {
			height: 100%;
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			margin-left: -25%;
			margin-top: -25%;

			cursor: pointer;

			&:hover {
				scale: 1.1;
			}
		}

		.editorVideo {
			transition: 0.3s;
			cursor: pointer;

			> video {
				transition: 0.3s;
			}

			&:hover {
				> video {
					filter: brightness(0.4);
				}
			}
		}
	}

	footer {
		display: flex;
		justify-content: space-around;
		align-items: center;

		color: var(--onPrimary);
		background-color: black;
		width: 100%;
		text-align: center;
		padding: 3em;
	}
}

@media (min-width: 768px) {
	.snapWrapper {
		.landingHeader {
			.headerContent {
				margin: auto;
				width: 100%;
				grid-template-columns: 6fr 3fr 6fr;
				grid-template-rows: 2fr 5fr 2fr 1.5fr 1fr;
				grid-template-areas:
					". . ."
					"head head pics"
					". . ."
					"buttons . saiba"
					". . .";

				.headText {
					font-size: 4vw;
					align-self: center;
					border-radius: 10px;
					background-color: #0000;
					backdrop-filter: none;
				}

				.saiba {
					justify-self: end;
					align-self: center;

					cursor: pointer;
				}
			}
		}
	}
}
