.cardPreview {
    transition: 0.3s;

    background: linear-gradient(#fff, #fff) padding-box, linear-gradient(100deg, var(--primary), var(--secondary)) border-box !important;
    border: 3px solid transparent !important;
    border-radius: 1rem !important;

    scroll-snap-align: center;

    scroll-snap-stop: always;




    &:hover {
        scale: 1.05;
        box-shadow: 0 5px 10px 1px var(--primary);
    }

    .cardSubMenuIcon,
    .cardFavorite {
        &:hover {
            background-color: transparent;
            scale: 1.05;
        }
    }

    .cardFavorite {
        position: absolute;
        top: 0.875rem;
        right: 0.5rem;
        color: var(--primary);
    }
}