.amplify-button--primary {
  background: var(--primary);
}

.amplify-tabs__item--active{
  border-color: var(--primaryVariant);
  color: var(--primaryVariant);
}

.amplify-tabs__item:hover {
  color: var(--accent);
}