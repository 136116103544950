.body {
	display: flex;
	flex-direction: column;
	gap: 1em;

	.buttonWrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 1em;
	}
}
