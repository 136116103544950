.stepCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5em;

  max-width: 400px;

  .btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 0.5em;

    height: 100%;
    width: 100%;

    // background-color: var(--primaryVariant);
  }
}
