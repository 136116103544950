.object_menu{
    display: flex;
    width: 100%;
    height: 10vh;
    .nav_menu{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        background-color: var(--background);
        .menu_option {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            transition: 0.2s;
            >span{
                padding: 0em 0.5em;
                border-radius: 500px;
                transition: 0.3s;
            }
            &:hover{
                span{
                    background: var(--primaryVariant);
                }
                p{
                    font-weight: 800;
                }
            }
            &.active{
                scale: 1.05;
                span{
                    color: var(--onPrimary);
                    background: var(--primary);
                    font-size: 24pt;
                }
            }
            >span{
                font-size: 24pt;
            }
        }
    }
    .context_menu{
        display: flex;
        transition: 0.5s;
        max-height: 0vh;
        min-height: 0vh;
        overflow-y: auto;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 10vh;
        width: 100%;
        background: var(--background);
        >*{
            width: 96%;
        }
        &.active{
            max-height: 90vh;
            min-height: 30vh;
        }
        .header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 10px;
            padding-left: 20px;
        }
        .body{
            padding: 3%;
            background: white;
            border-radius: 10px;
            box-shadow: inset 0 0 3px #333;
            margin-bottom: 10px;
        }
    }
}
