div.topLayout nav {
    background-color: var(--primary);
    box-shadow: 0 0 10px #555;
    display: flex;
    justify-content: center;
    .wrapper{
        width: 100%;
        padding: 0.5em 2em 0.5em 2em;
        max-width: 1300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    h1 {
        font-size: 40px;
        color: var(--onPrimary);
        line-height: 45px;
    }

    aside {
        display: flex;

        >* {
            color: var(--onPrimary);
            text-decoration: none;
            padding: 0;
            line-height: 45px;
        }

        img {
            height: 100%;
            aspect-ratio: 1;
            background-color: var(--background);
            border-radius: 50%;
        }

        .tab {
            transition: 0.2s;
            padding: 0 1em 0 1em;
            margin-left: 1em;

            &:hover {
                scale: 1.05;
            }

            &.active {
                scale: 1.05;
                background-color: #fff2;
                border-radius: 500px;
            }
        }

        .profileMenu {
            background-color: transparent;
            color: white;
            margin-left: 1em;

            &:hover {
                opacity: 0.9;
            }

            .profileWrapper {
                display: flex;
                align-items: center;
    
                cursor: pointer;
    
                .text {
                    margin-left: 5px;
                }
            }

        }

        .mobileProfileWrapper {
            display: flex;
            align-items: center;

            cursor: pointer;

            .text {
                margin-left: 5px;
            }
        }

    }
}

div.topLayout main {
    margin-top: 1em;
}

.MuiBadge-colorSecondary {
    box-shadow: none !important;
}

.topMenuLink {
    display: flex;
    align-items: center;
    gap: 0.5em;
    color: black;
    text-decoration: none;
    font-weight: normal;
}